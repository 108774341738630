<template>
  <div class="footer">
    <div class="container">
      <div class="row">
        <div class="col-md-10 footer_l">
          <p>
            <span>主办单位：乌鲁木齐建设职业培训中心     </span>
            <span>  承办单位：乌鲁木齐建设职业培训中心     </span>
            <span>  地址：天山区西河坝前街111号</span>
          </p>
          <p>
            <span>技术咨询：09912825927 ，09912827027</span>
            <span> 报名咨询：09912821187，09912812728，09912819548</span>
            <span> 网站投诉邮箱：2477015735@qq.com</span>
          </p>

          <p>
            ICP备案号：<a
              class=""
              href="https://beian.miit.gov.cn"
              target="_blank"
          >
            <span style="color:#fff">新ICP备20000245</span>
          </a>
          </p>

          <div style="width: 300px; margin: 0 auto; padding: 20px 0 10px">
            <a
                target="_blank"
                href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=65010202001153"
                style="
                display: inline-block;
                text-decoration: none;
                height: 20px;
                line-height: 20px;
              "
            ><img
                src="../assets/beian.jpg"
                style="float: left; margin-top:0px"
            /><p
                style="
                  float: left;
                  height: 20px;
                  line-height: 20px;
                  margin: 0px 0px 0px 5px;
                  color: #939393;
                "
            >新公网安备 65010202001153号
            </p></a
            >
          </div>
          <div class="footer_r">
            <img src="https://www.wlmqcol.com/files/system/block_picture_1588624630.jpg" />
            <p>扫描二维码进入微信公众号</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.footer {
  padding: 40px 0 10px 0;
  height: auto;
  overflow: hidden;
  background: #313131;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  span {
    // margin: 0 10px;
  }
  .footer_l {
    padding: 0px 0 0 0;
    position: relative;
    p{
      // padding-top: 10px;
    }
  }
  .footer_r{
    position: absolute;
    top: -13px;
    right:30px;
    text-align: center;
  }
}
.container{
  width: 1150px;
  margin: 0px auto;
  text-align: left;
  font-size: 14px;
  text-align: left;
  line-height: 30px;
}
</style>