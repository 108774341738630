<template>
  <div class="rf_bar">
    <div class="tps">
      <div class="fls">
        <a href="javascript:;" class="btn">
          <i class="ico ico1"></i>
          <span>电话咨询</span>
        </a>
        <div class="sw sw1">
          <dl>
<!--            <dt>电话:</dt>-->
            <dt>特种作业人员新取证及复审：0991-2821187</dt>
            <dt>岗位人员新取证：0991-2812728</dt>
            <dt>燃气从业人员新取证：0991-3211888</dt>
            <dt>岗位、二建、安管人员继续教育：0991-2825927</dt>
            <dt>水利安管人员新取证：0991-2827027</dt>
            <dt>监理员新取证及继续教育：0991-3211888</dt>
            <dt>试验员新取证及继续教育：0991-3211888</dt>
            <dt>技术工人新取证：0991-3211888</dt>
            <dt>自治区、乌鲁木齐市职称继续教育：0991-8879298</dt>
          </dl>
          <div class="tip">咨询时间:11:00~18:00(工作日)</div>
          <i class="sj"></i>
        </div>
      </div>

      <div class="fls">
        <a href="javascript:;" class="btn">
          <i class="ico ico2"></i>
          <span>公众号</span>
        </a>
        <div class="sw sw4">

          <img src="../assets/OpenAccountIMG.jpg" alt="">


          <i class="sj"></i>
        </div>
      </div>
      <div class="fls">
        <a href="javascript:;" class="btn">
          <i class="ico ico2"></i>
          <span>服务号</span>
        </a>
        <div class="sw sw4">

          <img src="../assets/ServiceAccountIMG.jpg" alt="">


          <i class="sj"></i>
        </div>
      </div>
      <div class="fls">
        <a href="javascript:;" class="btn">
          <i class="ico ico4"></i>
          <span>小程序</span>
        </a>
        <div class="sw sw4">

          <img src="../assets/QRCodeIMG.jpg" alt="">


          <i class="sj"></i>
        </div>
      </div>


    </div>
    <a href="#top" class="go_top">
      <span>返回顶部</span>
    </a>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>

.rf_bar{
  width: 70px;
  position: fixed;
  right: 0;
  top: 50%;
  margin-top:-220px;
  z-index: 100;
}
.rf_bar .tps{
  width: 70px;
  /*height: 350px;*/
  background:#fff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  border-radius:5px;
}
.rf_bar .fls{
  position: relative;
}
.rf_bar .btn{
  display: block;
  height: 60px;
  padding-top:9px;
  border-bottom:1px solid #f0f0f0;
  text-align: center;
  color:#4e4e4e;
  font-size:13px;
  line-height: 28px;
}
.rf_bar .fls:hover .btn{
  color:#fff;
  background:#1957c4;
}
.rf_bar .ico{
  display: block;
  height: 28px;
  background-repeat:no-repeat;
  background-position:center center;
  width: 28px;
  margin: 0 auto;
  display: block;

}
.rf_bar .ico1{
  background-image:url("../assets/dianhua.png");
  background-size: 100%;
}
.rf_bar .fls:hover .ico1{
  background-image:url("../assets/dianhua1.png");
  background-size: 100%;
}
.rf_bar .ico2{
  background-image:url("../assets/weixin.png");
  background-size: 100%;
}
.rf_bar .fls:hover .ico2{
  background-image:url("../assets/weixin1.png");
  background-size: 100%;
}
.rf_bar .ico3{
  background-image:url("../assets/qiyeweixin.png");
  background-size: 100%;
}
.rf_bar .fls:hover .ico3{
  background-image:url("../assets/qiyeweixin1.png");
  background-size: 100%;
}
.rf_bar .ico4{
  background-image:url("../assets/xiaochengxu.png");
  background-size: 100%;
}
.rf_bar .fls:hover .ico4{
  background-image:url("../assets/xiaochengxu1.png");
  background-size: 100%;
}
.rf_bar .ico5{
  background-image:url("https://stu.xadi.com.cn/static/default/img/index/rf5.png");
}
.rf_bar .fls:hover .ico5{
  background-image:url("https://stu.xadi.com.cn/static/default/img/index/rf5h.png");
}
.rf_bar .go_top{
  width: 70px;
  height: 70px;
  margin-top:6px;
  border-radius:5px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  display: block;
  background:#fff url('../assets/gototop.png') no-repeat center 14px;
  font-size:12px;
  text-align: center;
  position: relative;
  color: #666;
  padding-bottom: 5px;
}
.rf_bar .go_top span{
  display: block;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 10px;
}
.rf_bar .sw{
  position: absolute;
  right: 80px;
  top: 0;
  z-index: 100;
  background:#fff;
  border-radius:5px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  display: none;
}
.rf_bar .fls:hover .sw{
  display: block;
}
.rf_bar .sj{
  display: block;
  width: 10px;
  height: 10px;
  background:#fff;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  right: -5px;
  top: 25px;
}
.rf_bar .sw1{
  width: 400px;
  padding:7px 0 0 17px;
  text-align: left;
}
.rf_bar .sw1 dd{
  line-height: 21px;
  font-size:12px;
}
.rf_bar .sw1 dt{
  height: 22px;
  padding-left:22px;
  line-height: 22px;
  background:url("https://stu.xadi.com.cn/static/default/img/index/rf_tel.png") no-repeat left center;
}
.rf_bar .sw1 .tip{
  height: 26px;
  line-height: 26px;
  font-size:12px;
  color:#999;
  white-space:nowrap;
}
/*--*/
.rf_bar .sw2{
  padding:7px 0 0 17px;
}
.rf_bar .sw2 dd{
  line-height: 21px;
  font-size:12px;
}
.rf_bar .sw2 dt{
  height: 22px;
  padding-left:22px;
  line-height: 22px;
  background:url("https://stu.xadi.com.cn/static/default/img/index/rf_tel.png") no-repeat left center;
}
.rf_bar .sw2 .tip{
  height: 26px;
  line-height: 26px;
  font-size:12px;
  color:#999;
  white-space:nowrap;
}/*--*/
.rf_bar .sw4{
  padding:30px;
}
.rf_bar .sw4 img{
  width: 100px;
  height: 100px;
}
.rf_bar .sw4 dd{
  line-height: 21px;
  font-size:12px;
}
.rf_bar .sw4 dt{
  height: 22px;
  padding-left:22px;
  line-height: 22px;
  background:url("https://stu.xadi.com.cn/static/default/img/index/rf_tel.png") no-repeat left center;
}
.rf_bar .sw4 .tip{
  height: 26px;
  line-height: 26px;
  font-size:12px;
  color:#999;
  white-space:nowrap;
}
.rf_bar a{
  text-decoration: none;
}
</style>